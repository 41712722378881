<template>
  <div>
    <el-main>
      <el-form class="el-form-search" label-width="120px">
        <el-form-item label="订单编号：">
          <el-input size="small" v-model="searchForm.order_no"></el-input>
        </el-form-item>
        <el-form-item label="付款人：">
          <el-input size="small" v-model="searchForm.name" ></el-input>
        </el-form-item>
        <el-form-item label="申请时间：">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="searchForm.create_time"
            @change="val => (val ? '' : (searchForm.create_time = []))"
            size="small"
            type="daterange"
            value-format="timestamp"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="服务名称：">
          <el-input size="small" v-model="searchForm.service_name" ></el-input>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select v-model="searchForm.status" size="small" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="待付款" :value="10"></el-option>
            <el-option label="待接单" :value="20"></el-option>
            <el-option label="进行中" :value="30"></el-option>
            <el-option label="待确认" :value="50"></el-option>
            <el-option label="待签字" :value="60"></el-option>
            <el-option label="已完成" :value="70"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-tabs type="card" v-model="searchForm.refund_state" @tab-click="tabClick">
        <el-tab-pane name="">
          <span slot="label">全部订单（{{ order_num.all }}）</span>
        </el-tab-pane>
        <el-tab-pane name="10">
          <span slot="label">待技师确认（{{ order_num.wait_artisan }}）</span>
        </el-tab-pane>
        <el-tab-pane name="40">
          <span slot="label">物业介入（{{ order_num.property_in }}）</span>
        </el-tab-pane>
        <el-tab-pane name="70">
          <span slot="label">平台介入（{{ order_num.platform_in }}）</span>
        </el-tab-pane>
        <el-tab-pane name="20">
          <span slot="label">退款成功（{{ order_num.refund_success }}）</span>
        </el-tab-pane>
        <el-tab-pane name="0">
          <span slot="label">退款关闭（{{ order_num.refund_close }}）</span>
        </el-tab-pane>
        <el-tab-pane name="30">
          <span slot="label">驳回申请（{{ order_num.reject }}）</span>
        </el-tab-pane>
      </el-tabs>
      <div class="order-list">
        <table class="order-table">
          <thead>
            <tr>
              <th>服务信息</th>
              <th>订单状态</th>
              <th>支付方式</th>
              <th>首尾款支付金额</th>
              <th>退款金额</th>
              <th>付款人</th>
              <th>申请时间</th>
              <th>退款状态</th>
              <th>操作</th>
            </tr>
            <tr style="height: 10px"></tr>
          </thead>
          <tbody v-if="!list.length">
            <tr style="background: #fff">
              <td colspan="11">
                <No />
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, index) in list" :key="index">
            <tr>
              <td colspan="11">
                <div class="order-head">
                  <div class="left">
                    <span class="sn">订单编号：{{ item.order_sn }}</span>
                    <span class="sn">退款单号：{{ item.refund_no }}</span>
                    <div >

                    </div>
                  </div>
                  <div class="right">
                    <el-button type="text" @click="$router.push({ path: '/service/repairAfterSaleInfo', query: { id: item.id } })">订单详情</el-button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="service-info">
                  <div class="item">
                    <el-image :src="item.service_cover_picture"></el-image>
                    <div class="service-title">
                      <p>{{ item.service_name }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <td>   <span style="color: #409eff">
                  {{
                      item.status == 0
                      ? '已关闭'
                      : item.status == -10
                          ? '已关闭'
                          : item.status == -20
                              ? '已关闭'
                              : item.status == 10
                                  ? '待付款'
                                  : item.status == 20
                                      ? '待接单'
                                      : item.status == 30 || item.status == 40
                                          ? '进行中'
                                          : item.status == 50
                                              ? '待确认'
                                              : item.status == 60
                                                  ? '待签字'
                                                  : item.status == 70 || item.status == 80
                                                      ? '已完成'
                                                      : '--'
                  }}
                </span>
              </td>
              <td>
                  {{
                      item.deposit_pay_type == 10
                      ? '微信支付'
                      : item.deposit_pay_type == 20
                          ? '支付宝支付'
                          : '--'
                  }}
              </td>
              <td>
                {{ item.total_price }}
              </td>
              <td>
                {{ item.refund_money }}
              </td>

              <td>
                <p  style="line-height: 20px">
                    {{item.name}}
                </p>
                <p style="line-height: 20px">{{ item.mobile }}</p>
              </td>
              <td>
                {{ getDateformat(item.create_time) }}
              </td>
              <td>
                  {{
                      item.refund_status == 10
                      ? '待处理'
                      : item.refund_status == 20
                          ? '技师同意'
                          : item.refund_status == 30
                              ? '技师驳回'
                              : item.refund_status == 40
                                  ? '申请物业介入'
                                  : item.refund_status == 50
                                      ? '物业同意'
                                      : item.refund_status == 60
                                          ? '物业拒绝'
                                          : item.refund_status == 70
                                              ? '申请平台'
                                              : item.refund_status == 80
                                                  ? '平台同意'
                                                  : item.refund_status == 90
                                                      ? '平台拒绝'
                                                      : '用户取消'
                  }}
              </td>
              <td>
                <el-button v-if="item.refund_status == 70" @click="handleRefund(item)" type="primary" size="small">退款</el-button>
              </td>
            </tr>
<el-dialog title="退款" :visible.sync="refundmoney" width="50%">
<!-- 小卡片 -->
<ul class="cardUl">
  <li class="cardLi">
    <div class="content cardLiTab">
      <div class="content cardLiTab">
        <el-form ref="form" :model="form" label-width="100px" label-position="left">
        <el-form-item label="处理方式：">
          <span>退款</span>
        </el-form-item>
        <el-form-item label="退款金额：">
          <span>{{item.refund_money}}</span>
        </el-form-item>
        <el-form-item label="处理结果：">
          <el-radio-group v-model="form.status">
            <el-radio label="1" value="1">通过</el-radio>
            <el-radio label="-1" value="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="驳回原因：" v-show="form.status==-1">
          <el-input type="textarea" v-model="form.reason" placeholder="请添加驳回原因"></el-input>
        </el-form-item>
        <p v-show="!form.status" class="resText">注意：点击确定，则表示同意退款，贷款会自动原路退回买家账号</p>
        <el-form-item class="formBtn">
          <el-button @click="refundmoney = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit(item)">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</li>
</ul>
</el-dialog>
          </tbody>
        </table>
      </div>
      <!-- <handle-refund v-if="showHandleRefund" :type="1" :refund_amount="currentInfo.refund_amount" :state="currentInfo.handle_state" :id="currentInfo.id"></handle-refund> -->
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>


  </div>
</template>

<script>
import Paging from '@/components/paging';
//   import handleRefund from './components/handleRefund';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
    //   handleRefund,
  },
  data() {
    return {
      keywords_type: {
        order_no: '',
        receiver_phone: '',
        receiver_name: '',
        service_name: '',
        name: '',
        store_id: '',
      },
      searchForm: {
        page: 1,
        rows: 10,
        order_no:'',
        name:'',
        service_name: '',
        create_time: [],
        refund_state: ' ',
      },
      currentInfo: {},
      showHandleRefund: !1,
      list: [],
      total_number: 0,
      order_num: {},
      refundmoney:false,
      form: {
        radio: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleRefund(row) {
      this.currentInfo = row;
      this.showHandleRefund = !0;
      this.refundmoney = true;

    },
    onSubmit(row){
        this.form.id= this.currentInfo.id
        let data = this.form
        this.$axios.post(this.$api.repair.service.orderRefundDeal, data).then(res => {
            if (res.code == 0) {
                this.getList();
            }
        });
    },
    tabClick() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList(1);
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        payment_code: '',
        refund_state: '',
      };
      this.resetKeywordType();
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    resetKeywordType(key) {
      for (let i in this.keywords_type) {
        if (i != key) this.keywords_type[i] = '';
      }
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.resetKeywordType('order_no');
          searchForm.keywords_type = 'order_no';
          searchForm.order_no=val;
          break;
        case 2:
          this.resetKeywordType('name');
          searchForm.keywords_type = 'name';
          searchForm.name=val;
          break;
        case 3:
          this.resetKeywordType('service_name');
          searchForm.keywords_type = 'service_name';
          break;
        case 4:
          this.resetKeywordType('receiver_name');
          searchForm.keywords_type = 'receiver_name';
          break;
        case 5:
          this.resetKeywordType('receiver_phone');
          searchForm.keywords_type = 'receiver_phone';
          break;
      }
      searchForm.keywords = val;
    },
    getList(is_tab) {
      let searchForm = Object.assign(this.searchForm);
      let data = this.searchForm
      if (searchForm.refund_state) {
        data.refund_state = searchForm.refund_state;
      }
      if (searchForm.keywords) {
        data.keywords = searchForm.keywords;
        data.keywords_type = searchForm.keywords_type;
      }
      if (searchForm.payment_code) {
        data.payment_code = searchForm.payment_code;
      }
      if (searchForm.create_time.length) {
          data.start_time = searchForm.create_time[0] / 1000;
          data.end_time = searchForm.create_time[1] / 1000;
      }
      this.$axios.post(this.$api.repair.service.orderRefundList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
      if (!is_tab) {
        this.$axios.post(this.$api.repair.service.orderRefundNum, data).then(res => {
          if (res.code == 0) {
            this.order_num = res.result;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .order-table {
    width: 100%;
    font-size: 14px;
    tbody {
      border: 1px solid #ebeef5;
    }
    thead tr th {
      padding: 15px;
      text-align: center;
    }
    thead tr {
      background: #f8f9fa;
      th:first-child {
        width: 280px;
      }
      th:last-child {
        width: 150px;
      }
    }
    tbody tr:first-child {
      border-bottom: 1px solid #ebeef5;

      background: #f8f9fa;
      td {
        padding: 0 20px;
      }
    }
    tbody tr:last-child {
      td {
        text-align: center;
      }
      td:first-child {
        width: 280px;
      }
      td:last-child {
        width: 150px;
      }
    }
    .service-info {
      width: 300px;
      flex: none;
      padding-left: 20px;

      .item {
        height: 70px;
        display: flex;
        align-items: center;
        .el-image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 5px;
        }
        .service-title {
          text-align: left;
          flex: 1;
          p:nth-child(1) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .order-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        .sn {
          margin-right: 70px;
        }
        div {
          display: flex;
          align-items: center;
        }
        .el-button {
          cursor: auto;
        }
      }
    }
  }
}
.cardUl {
  width: 100%;
  background-color: #fff;
  flex-wrap: wrap;

  li {
    position: relative;

    .cardLiTil {
      background-color: #ececec;
      color: gray;
      font-size: 18px;
      height: 50px;
      padding: 20px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .cardLiTab {
      padding: 20px;
    }

    .cardTabPage {
      display: flex;
      justify-content: center;

      position: absolute;
      bottom: 20px;
      left: 40%;
    }
  }
}

.resText {
  color: red;
  font-size: 14px;
}

.formBtn {
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
